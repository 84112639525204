<template>
  <div class="home">
    <div class="container is-fluid">
      <page-header :entity="$EntitiesName.Role" :create="openCreateModal">
        <h1 class="title">Rôles</h1>
      </page-header>

      <datatable :fetch="$Api.Role.fetchRoles" :entity="$EntitiesName.Role" ref="roleList">
        <div slot="name" slot-scope="{ item }" title="Nom" :sortable="true">{{ item.name }}</div>
        <div slot="description" slot-scope="{ item }" title="Description" :sortable="true">{{ item.description }}</div>
      </datatable>
    </div>

    <modal ref="createRoleModal" @create="createRole" title="Création d'un rôle">
      <div class="columns">
        <div class="column is-half">
        <ValidationObserver ref="form">
          <text-field label="Nom" v-model="role.name" :inline="false" :edit="true" required :rules="'min:3'" data-test="sel-role-name"></text-field>
          <textarea-field
            label="Description"
            v-model="role.description"
            :inline="false"
            :edit="true"
            required
            data-test="sel-role-description"
          ></textarea-field>
          <selector-field
            :options="$Api.Role.fetchVisibilityTypes"
            label="Visibilité"
            v-model="role.visibility"
            :inline="false"
            :edit="true"
            data-test="sel-role-visibility"
          ></selector-field>
          </ValidationObserver>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import axios from 'axios';
import Datatable from '../../components/elements/Datatable';

export default {
  name: 'roles',
  components: {
    Datatable,
  },
  data() {
    return {
      role: {
        visibility: this.$Api.Role.fetchVisibilityTypes()[1]
      },
    };
  },
  methods: {
    openCreateModal() {
      this.$refs.createRoleModal.open();
    },
    createRole() {
      this.$refs.form.validate().then(success => {
        if (!success) {
          return;
        }
        const role = {
          ...this.role,
        };
        const visibilityTypes = this.$Api.Role.fetchVisibilityTypes();
        const visibilityCode = visibilityTypes.findIndex(
          item => item === role.visibility,
        );

        role.visibility = visibilityCode + 1;
        axios.post('/roles', role).then((response) => {
          if (response.data.success) {
            this.role = {};
            this.$refs.roleList.refresh();
            this.$refs.createRoleModal.close();
            this.$router.push({
              name: 'Role',
              params: { id: response.data.id },
            });
          }
        });
      })
    },
  },
};
</script>

<style scoped>
</style>
